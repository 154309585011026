import * as React from "react"
import MainLayout from '../layouts/main-layout';
import { WideVideo, LandingPageImage } from '../components';
import LandingPageOverview from './landing/lp-overview';
import ContactBanner from './landing/contact-banner';
import { Paper, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import CocoaBeansImg from '../assets/images/cocoa-beans.png';
import LandfillImg from '../assets/images/rubbish-heap.jpg';
import FarmerImg from '../assets/images/women-farmers.png';
import OrangutanImg from '../assets/images/orangutan.png';
import NetworkImg from '../assets/images/network.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";

// import CocoaBeansVideo from '../assets/video/cocoa-beans.mp4';
// import FarmerVideo from '../assets/video/farmer.mp4';
// import FutureVideo from '../assets/video/future.mp4';
// import LandfillVideo from '../assets/video/landfill.mp4';
// import OrgangutanVideo from '../assets/video/orangutan.mp4';

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    height: '80vh',
    overflow: 'hidden',

    '& .carousel-root': {
      height: '100%',

      '& .carousel-slider': {
        height: '100%',

      }
    },

    '& .slider-wrapper': {
      height: '100%',

      '& ul': {
        height: '100%',

        '& li': {
          height: '100%',

          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
      }      
    }
},
slideImage: {
  opacity: 1,
},
carouselSlide: {
  display: 'flex',
  height: '100%',
},
  // carouselSlide: {
  //   display: 'none',

  //   '&.active': {
  //     display: 'block',
  //     animation: 'slideIn 1s forwards',
  //     animationDelay: '0.5s',
  //   },

  //   opacity: 0,
  //   willChange: 'translate, opacity',
  //   transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
  //   transform: 'translateZ(0)',
  //   borderBottom: '20px solid #fff',
  //   boxShadow: 'none',
  //   borderRadius: 0,
  // },
  loadingPage: {
    width: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: '#fff',
    height: '100vh',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const IndexPage = () => {
  // const [ carouselIndex, setCarouselIndex ] = React.useState(0);
  // const [ loadedVideos, setLoadedVideos ] = React.useState({});
  // const [ allVideosLoaded, setAllVideosLoaded ] = React.useState(false);
  const classes = useStyles();

//   const videos = [
//     {
//       key: 'lp-video-5',
//       posterUrl: 'https://ak.picdn.net/shutterstock/videos/28080601/thumb/11.jpg',
//       webmUrl: 'https://ak.picdn.net/shutterstock/videos/28080601/preview/stock-footage--d-animation-dots-connecting-line-dots-makes-global-world-map-internet-of-things.webm',
//       mp4Url: FutureVideo,
//       text: [{ type: 'basic', text: 'The', underline: true },{ type: 'basic', text: 'Supply' },{ type: 'basic', text: 'Network' },{ type: 'basic', text: 'Of' },{ type: 'basic', text: 'The' },{ type: 'bold', text: 'Future', color: '#a6d7d4' }]
//     },
//   {
//     key: 'lp-video-1',
//     posterUrl: 'https://ak.picdn.net/shutterstock/videos/1009305542/thumb/9.jpg',
//     webmUrl: 'https://ak.picdn.net/shutterstock/videos/1009305542/preview/stock-footage-organic-red-cocoa-bean-pods-hanging-on-tree-branch-at-plantation-farm-k.webm',
//     mp4Url: CocoaBeansVideo,
//     text: [{ type: 'basic', text: 'Fighting', underline: true }, { type: 'bold', text: 'Exploitation', color: '#fe9c9c' }]
//   },
//   {
//     key: 'lp-video-2',
//     posterUrl: 'https://ak.picdn.net/shutterstock/videos/1009407242/thumb/1.jpg',
//     webmUrl: 'https://ak.picdn.net/shutterstock/videos/1009407242/preview/stock-footage-orangutan-female-feeding-with-fruits.webm',
//     mp4Url: OrgangutanVideo,
//     text: [{ type: 'basic', text: 'Protecting', underline: true }, { type: 'bold', text: 'Habitats', color: '#98eabe' }]
//   },
//   {
//     key: 'lp-video-3',
//     posterUrl: 'https://ak.picdn.net/shutterstock/videos/1038354653/thumb/1.jpg',
//     webmUrl: 'https://ak.picdn.net/shutterstock/videos/1038354653/preview/stock-footage-rear-of-the-african-american-male-farmer-in-a-hat-walking-the-soy-green-field-on-the-sunset-towards.webm',
//     mp4Url: FarmerVideo,
//     text: [{ type: 'basic', text: 'Building', underline: true }, { type: 'bold', text: 'Transparency', color: '#98eabe' }]
//   },
//   {
//     key: 'lp-video-4',
//     posterUrl: 'https://ak.picdn.net/shutterstock/videos/1008733682/thumb/11.jpg',
//     webmUrl: 'https://ak.picdn.net/shutterstock/videos/1008733682/preview/stock-footage-city-dump-the-bulldozer-moves-along-the-landfill-leveling-the-garbage-gulls-feeding-on-food.webm',
//     mp4Url: LandfillVideo,
//     text: [{ type: 'basic', text: 'Reducing', underline: true }, { type: 'bold', text: 'Waste', color: '#fe9c9c' }]
//   },
// ]

  // const handleVideoEnded = ( videoRef, index ) => {
  //   const nextIndex = (videos.length - 1) <= index ? 0 : index + 1;
  //   setCarouselIndex(nextIndex);
  // };

  // const handleVideoLoaded = ( src ) => {
  //   if (loadedVideos.hasOwnProperty(src))
  //     return;

  //   const newLoadedVideos = {
  //     ...loadedVideos,
  //     [ src ]: 'loaded'
  //   };

  //   setLoadedVideos(newLoadedVideos);
  // }

  // React.useEffect(() => {
  //   if ( !allVideosLoaded && Object.keys(loadedVideos).length === videos.length )
  //     setAllVideosLoaded(true);

  // }, [ loadedVideos ]);

  const slideImages = [
    {
      url: NetworkImg,
      caption: 
        [{ type: 'basic', text: 'The', underline: true },{ type: 'basic', text: 'Supply' },{ type: 'basic', text: 'Network' },{ type: 'basic', text: 'Of' },{ type: 'basic', text: 'The' },{ type: 'bold', text: 'Future', color: '#a6d7d4' }]
      
    },
    {
      url: CocoaBeansImg,
      caption: [{ type: 'basic', text: 'Fighting', underline: true }, { type: 'bold', text: 'Exploitation', color: '#fe9c9c' }]
      
    },
    {
      url: OrangutanImg,
      caption: [{ type: 'basic', text: 'Protecting', underline: true }, { type: 'bold', text: 'Habitats', color: '#98eabe' }]
    },
    {
      url: FarmerImg,
      caption: [{ type: 'basic', text: 'Building', underline: true }, { type: 'bold', text: 'Transparency', color: '#98eabe' }]
    },
    {
      url: LandfillImg,
      caption: [{ type: 'basic', text: 'Reducing', underline: true }, { type: 'bold', text: 'Waste', color: '#fe9c9c' }]
    },
  ];

  const LoadingPage = () => (
    <div className={classes.loadingPage}>
      <CircularProgress />
    </div>
  )

  const loadedFirstVideo = true

  return (
    <MainLayout>
    <main>
      <title>QNOS</title>
      <div className={classes.carouselContainer}>
        <Carousel showArrows={false} autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} interval={5000}>
          {slideImages.map((slideImage, index)=> (
              <div className={classes.carouselSlide} key={index}>
                <LandingPageImage image={<img className={classes.slideImage} src={slideImage.url} />} text={slideImage.caption} />
              </div>
            ))} 
        </Carousel>
      </div>

      {/* {
        videos.map((video, index) => {
          const play = loadedVideos[video.webmUrl] === 'loaded' && index === carouselIndex;
          const slideClasses = `${ classes.carouselSlide } ${ play ? 'active': '' }`;

          const vid = (
              <WideVideo
                onVideoEnded={(videoRef) => handleVideoEnded(videoRef, index)}
                fadeOut
                posterUrl={video.posterUrl}
                webmUrl={video.webmUrl}
                mp4Url={video.mp4Url}
                autoPlay={play}
                onVideoLoaded={handleVideoLoaded}
              />
          )

          return (
            <Paper className={slideClasses} key={video.key}>
              <LandingPageImage image={vid} text={video.text} />
            </Paper>
          );
        })
      } */}
      {
        !loadedFirstVideo && (
          <LoadingPage />
        )
      }
      {
        loadedFirstVideo && (
          <>
            <LandingPageOverview />
            <ContactBanner />
          </>
        )
      }
      
    </main>
    </MainLayout>
  )
}

export default IndexPage
