import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { WebsiteHeading } from '../../components';
import GlobeImage from '../../images/globe3.png';
import CheckboxImage from '../../images/board.png';
import ProfitDataChart from '../../images/chart-lg.png';
import PackagingBox from '../../images/box3.png';

const useStyles = makeStyles((theme) => ( {
    lpOverviewWrap: {
        width: '100%',
        background: '#fff',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '2rem',
    },
    lpMargin: {
        margin: '0px',
        maxWidth: '1024px',
    },
    lpTextBox: {
        padding: '2rem',
        fontFamily: 'Inter,sans-serif',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: '#485776',

        [theme.breakpoints.down(850)]: {
            width: '100% !important',
            order: '4 !important',
        }
    },
    lpHeading: {
        fontFamily: "'Catamaran', sans-serif",
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: '2.25rem',
        color: '#131f37',
    },
    lpRight: {
        order: 3,
        width: '50%',
    },
    lpLeft: {
        order: 1,
        width: '50%',
    },
    pageBlock: {
        display: 'flex',

        [theme.breakpoints.down(850)]: {
            flexDirection: 'column'
        }
    },
    lpImagePositioner: {
        order: 2,
        width: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        [theme.breakpoints.down(850)]: {
            width: '100% !important',
        }
    },
    lpImage: {
        width: '60%',

        [theme.breakpoints.down(850)]: {
            width: '40% !important',
        },

        [theme.breakpoints.down(540)]: {
            width: '50% !important',
        },
    }
} ));

const LandingPageOverview = () => {
    const classes = useStyles();

    return (
        <div className={classes.lpOverviewWrap}>
            <div className={classes.lpMargin}>
            <div className={classes.lpTextBox}>
                <WebsiteHeading text="We're Redesigning How Businesses & Consumers Get Goods" />
                <p>
                    We're building a fair and better way to trade goods worldwide that meets the demands of any business size 
                    and budget while keeping sustainability as the top priority. With the latest technology ranging from
                    artificial intelligence and machine learning to real time tracking and communication businesses will finally 
                    get the data and protection they need. 
                </p>
                <p>
                    Driving innovation to protect our planet is at the forefront of our smart
                    design. We're developing new ways to think about products - from cradle to grave - and providing
                    insights for both brands and consumers that simply do not exist at present. 
                </p>
                <p>It's time to revolutionise the way we consume.</p>
            </div>
            <div className={classes.pageBlock}>
                <div className={`${classes.lpTextBox} ${classes.lpRight}`}>
                    <WebsiteHeading text="Designed For Everyone" />
                    <p>
                        Enterprise software is only half of the battle. Exploitation of the worlds poorest can't be solved without
                        smart design, communication, education and protection. By working closely with makers, farmers, cooperatives
                        and manufacturers we're working on a solution that truly works for everyone.
                    </p>
                    <p>
                        By having validators check in on suppliers, listen to concerns and verify compliance is met our solution 
                        isn't just a sexy bit of tech. It's an evolving eco system with rapid response offering protection for 
                        brands and suppliers where no voice goes unheard. 
                    </p>
                </div>
                <div className={classes.lpImagePositioner}>
                    <img className={classes.lpImage} src={CheckboxImage} alt="verifier ticking checkboxes" />
                </div>
            </div>
            <div className={classes.pageBlock}>
                <div className={`${classes.lpTextBox} ${classes.lpLeft}`}>
                    <WebsiteHeading text="Seriously Compelling" />
                    <p>
                        Our solutions provide benefits not just for the planet, but also our clients. With our smart design you'll
                        get features and insights that simply do not exist at present, as well as plenty that are only a dream for 
                        small businesses. It's time to get smarter and obtain data that will guide your business to new horizons.
                    </p>
                    <p>
                        Fraudulent activity is hard to solve within complex supply chains. But using new technologies we can help
                        protect brands and add further layers of security to keep brand images strong and intact. 
                    </p>
                </div>
                <div className={classes.lpImagePositioner}>
                    <img className={classes.lpImage} src={ProfitDataChart} alt="Line chart showing an increase in data corresponds to an increase in profit" />
                </div>
            </div>
            <div className={classes.pageBlock}>
                <div className={`${classes.lpTextBox} ${classes.lpRight}`}>
                    <WebsiteHeading text="Smarter Packaging" />
                    <p>
                        We're building a supply network that not only revolutionises the way products are sourced
                        but also how they're packaged. By providing a new way for brands to think about packaging and offer services that have 
                        never before been possible we will transform the waste industry.
                    </p>
                    <p>
                        By rethinking how we design and offer products we can also hugely reduce carbon emissions, deforestation and 
                        pollution whilst increasing brand profits, reputations and sustainability metrics. 
                    </p>
                </div>
                <div className={classes.lpImagePositioner}>
                    <img className={classes.lpImage} src={PackagingBox} alt="Bright light bulb inside a box" />
                
                </div>
            </div>
            <div className={classes.pageBlock}>
                <div className={`${classes.lpTextBox} ${classes.lpLeft}`}>
                    <WebsiteHeading text="Worldwide But Not One Entity" />
                    <p>
                        Our aim isn't to make as much profit as possible. It's about providing innovation and improving the world we live 
                        in. Instead of trying to expand through holding a tight grip and monopolising, we will provide a blueprint
                        whereby other businesses can join us where makes sense to do so. 
                    </p>
                </div>
                <div className={classes.lpImagePositioner}>
                    <img className={classes.lpImage} src={GlobeImage} alt="globe with connections" />
                </div>
            </div>
            <div className={classes.lpTextBox}>
                <WebsiteHeading text="The Road To Success" />
                <p>
                    To see what we've achieved and where we're up to we have a dedicated roadmap page <a href="/roadmap">here</a>. To truly succeed in our 
                    grand plan we will need your help. Shout out about us, tell your friends and follow us on social media or subscribe to 
                    our email list and stay informed. You can also visit our shop to support us even further 
                    at <a rel="noreferrer" href="https://tecorra.co.uk" target="_blank">tecorra.co.uk</a>.
                </p>
                <p>
                    We're now looking for retailers and brands in the coming months to join us through private R&amp;D. So if you're 
                    a curious business owner you can sign up to find out more, with no obligation <a href="/solutions/project-phoenix">here</a>. If you're a consumer who loves 
                    what you see we're doing, please do point your favourite brands our way. 
                </p>
                </div>
            </div>
        </div>
    );
}

export default LandingPageOverview;