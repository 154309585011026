import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ( { 
    bannerWrap: {
        background: '#F6F8FC',
        padding: '50px',
    },
    bannerContainer: {
        maxWidth: '1024px',
        margin: '0 auto',
    },
    bannerPadding: {
        padding: '2rem'
    },
    bannerHead: {
        color: '#131f37',
        fontSize: '2.25rem',
        fontFamily: "'Catamaran', sans-serif",
        fontWeight: 300,
        textTransform: 'capitalize',
        margin: 0,
    },
    formField: {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        borderBottomWidth: '1px',
        borderStyle: 'solid',
        fontSize: '1.125rem',
        marginBottom: '1rem',
        backgroundColor: 'transparent',
        width: '100%',
        border: '0 solid #b5b7bd',
        fontFamily: 'Inter,sans-serif',
        maxWidth: '500px',
        outline: 'none',
    },
    formRow: {
        width: '100%',
    },
    formMessage: {
        minHeight: '200px'
    },
    formDescription: {
        fontFamily: 'Inter,sans-serif',
        color: '#485776',
        padding: '1.3em 0',
        margin: 0,
    },
    formSubmitContainer: {
        display: 'flex',
        maxWidth: '500px',
        padding: '0.5rem',
    },
    formSubmitButton: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        borderWidth: '2px',
        borderColor: '#485776',
        cursor: 'pointer',
        fontWeight: 700,
        position: 'relative',
        backgroundColor: '#131f37',
        color: '#fff',
        display: 'inline-block',
        fontSize: '1rem',
        lineHeight: 1.25,
        textTransform: 'uppercase',
        borderRadius: '0.5rem',
        alignSelf: 'flex-end',
        transition: 'background 500ms linear',
        background: 'linear-gradient(120deg, #081831 50%, #131f37)',
        backgroundSize: '200% 100%',
        backgroundPosition: '0% 0%',
        outline: 'none',
        border: 0,
        margin: 'auto',
        width: '60%',

        '&:hover': {
            background: '#0FC1B7 !important',
            color: '#131f37'
        }
    }
} ));

const ContactBanner = () => {
    const classes = useStyles();
    const frameRef = React.useRef();

    const handleSubmit = () => {
        const r = frameRef;
        debugger;
    }

    return (
        <div className={classes.bannerWrap}>
            <div className={classes.bannerContainer}>
                <div className={classes.bannerPadding}>
                    <h3 className={classes.bannerHead}>
                        Contact Us
                    </h3>
                    <p className={classes.formDescription}>
                        Have a question? Send an email to hello@qnos.uk and we'll get back to you.
                    </p>
                    {/* <form className={classes.contactForm} onSubmit={handleSubmit}>
                        <div className={classes.formRow}>
                            <input className={classes.formField} type='text' name='name' placeholder='Name'  />
                        </div>
                        <div className={classes.formRow}>
                            <input className={classes.formField} type='email' name='email' placeholder='Email' />
                        </div>
                        <div className={classes.formRow}>
                            <textarea className={`${classes.formField} ${classes.formMessage}`} type='text' name='message' placeholder='Message' />
                        </div>     
                        <div className={classes.formRow}>
                            <div className={classes.formSubmitContainer}>
                                <button type='submit' className={classes.formSubmitButton}>Send</button>
                            </div>    
                        </div>  
                    </form> */}
                </div>
            </div>            
        </div>
    )
}

export default ContactBanner;